<template>
  <section>
    <nav-bar></nav-bar>
    <side-navbar></side-navbar>
    <card-cookie>
      <div class="container div-container">
        <h1>Export des données en CSV</h1>
        <b-button type="is-success" @click="getDataExport"
          >Exporter données en CSV</b-button
        >
        <b-button type="is-success second-button" @click="getDataExportContacts"
          >Exporter les données de la newsletter en CSV</b-button
        >
      </div>
    </card-cookie>
    <footer-cookie></footer-cookie>
  </section>
</template>

<script>
import { ExportToCsv } from 'export-to-csv';
import NavBar from '../components/NavBar.vue';
import FooterCookie from '../components/FooterCookie.vue';
import SideNavbar from '../components/SideNavbar.vue';
import CardCookie from '../components/CardCookie.vue';

const API_URL = process.env.VUE_APP_API_URL;
const options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  title: '',
  useTextFile: false,
  useBom: false,
  useKeysAsHeaders: true,
};
const csvExporter = new ExportToCsv(options);

export default {
  components: {
    NavBar,
    FooterCookie,
    SideNavbar,
    CardCookie,
  },
  data() {
    return {
      dataExport: [],
      dataExportContacts: [],
    };
  },
  methods: {
    getDataExport() {
      fetch(`${API_URL}/datas`, {
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('vue-token')}`,
          'Content-type': 'application/json',
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.dataExport = json;
          console.log('dataCsv', this.dataExport);
          csvExporter.generateCsv(this.dataExport);
        });
    },
    getDataExportContacts() {
      fetch(`${API_URL}/contacts`, {
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('vue-token')}`,
          'Content-type': 'application/json',
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.dataExportContacts = json;
          console.log('exportContacts', this.dataExportContacts);
          csvExporter.generateCsv(this.dataExportContacts);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.div-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.card-cookie {
  width: 65%;
  margin-top: 160px;
}

.button.is-success {
  margin-top: 20px;
  background-color: $primary;
  color: white;
}

.second-button {
  margin-bottom: 20px;
}
</style>